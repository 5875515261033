<template>
  <div class="commentupdate">
    <div class="uploadfile">
      <!-- <van-cell-group inset>
        <van-field
          v-model="desc"
          rows="2"
          autosize
          type="textarea"
          maxlength="500"
          show-word-limit
          :placeholder="lang.placeholder_work"
          @change="changeField"
        />
      </van-cell-group> -->
      <div
        class="uploadfile_item"
        v-for="(file, index) in uploadedList"
        :key="index"
      >
        <div class="title">{{ file.title }}</div>
        <van-icon
          name="cross"
          color="#a5907e"
          size="22"
          @click="deleteUploadedFile(file)"
        />
      </div>

      <van-uploader
        style="margin-top: 20px"
        max-count="1"
        accept="application"
        compressed="false"
        v-model="fileList"
        :max-size="10 * 1024 * 1024"
        @oversize="oversize"
        :before-read="beforeRead"
        :after-read="afterRead"
      >
        <van-button plain type="primary">{{ lang.select_file }}</van-button>
      </van-uploader>
    </div>
    <!-- <div class="footer_btn">
      <van-button type="primary" @click="confirmWork">{{
        lang.submit
      }}</van-button>
    </div> -->
  </div>
</template>
    
    <script>
import { reactive, toRefs, ref } from "vue";
import Common from "@/library/Common";
import Config from "@/library/Config";
import request from "@/library/MyRequest";
import { useRouter } from "vue-router";
import { Toast, Dialog } from "vant";

export default {
  name: "ClassEvaluation",
  components: {},
  setup() {
    const uploadFile = ref([]);
    const common = Common;
    const router = useRouter();
    const courseData = JSON.parse(router.currentRoute.value.query.courseData);
    const lesson_id = courseData["courseId"];
    let state = reactive({
      lang: {
        langBtn: "",
        upload_job: "",
        select_file: "",
        submit: "",
        jobSize: "",
        justUploadjob: "",
        filenametoolong: "",
        DeleteThisJob: "",
        JobUploaded: "",
        uploading: "",
        timeout: "",
        networkError: "",
        placeholder_work: "",
        confirm_work: "",
        yes: "",
        no: "",
      },
      uploadedList: [],
      fileList: [],
      uploadeditem: {},
      desc: "",
      placeholder: "填写作业内容…",
    });
    common.initLanguage(state.lang);
    const getList = async () => {
      var res = await request.get(`lesson/homework/?lesson_id=${lesson_id}`);
      console.log("getList", res);
      if (res.status == 200) {
        state.uploadedList = res.data.map((item, index) => {
          if (index == 0) {
            state.desc = item.desc;
          }
          return {
            id: item.id,
            title: item.title,
            name: item.title,
            desc: item.desc,
            url: item.file_url,
          };
        });
        console.log(" state.uploadedList ", state.uploadedList);
      }
    };
    const changeField = (e) => {
      console.log("changeField", state.desc);
    };
    const oversize = function () {
      Toast(state.lang.justUploadjob);
    };

    const hasFile = function (filename) {
      console.log("hasFile", filename);
      for (var file of state.uploadedList) {
        if (file.title == filename) {
          return true;
        }
      }
      return false;
    };
    const beforeRead = (File) => {
      if (File.name.length > 100) {
        Toast(state.lang.filenametoolong);
        return false;
      } else if (hasFile(File.name)) {
        Toast(state.lang.JobUploaded);
        return false;
      }
      if (
        File.type ==
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
        File.type == "application/msword" ||
        File.type == "application/pdf"
      ) {
        return true;
      } else {
        Toast(state.lang.justUploadjob);
        return false;
      }
    };

    const afterRead = async (event) => {
      console.log("afterRead", event);
      state.fileList = [];
      Dialog.confirm({
        title: state.lang.confirm_work,
        confirmButtonText: state.lang.yes,
        cancelButtonText: state.lang.no,
      }).then(async () => {
        uploadFile.value.splice(0, 1);
        Toast.loading({
          message: state.lang.uploading,
          forbidClick: true,
          overlay: true,
        });
        const file = event.file;
        const formData = new FormData();
        formData.append("file", file);
        try {
          var uploadRes = await request.upload(formData);
          if (uploadRes.status == 200) {
            let uploadfile = {
              lesson_id: lesson_id,
              title: file.name,
              desc: "",
              file_url: uploadRes.data.url,
            };
            state.uploadeditem = uploadfile;
            try {
              var res = await request.post("lesson/homework/", uploadfile);
              if (res.status == 201) {
                uploadfile.id = res.data.id;
                state.uploadedList.push(uploadfile);
              }
            } catch (e) {
              console.log(e.message);
            }
          }
        } catch (e) {
          Toast(e.message);
          console.log(e.message);
        }

        Toast.clear();
      });
    };

    const deleteUploadedFile = function (item) {
      Dialog.confirm({
        title: state.lang.DeleteThisJob,
        confirmButtonText: state.lang.yes,
        cancelButtonText: state.lang.no,
      }).then(async () => {
        var res = await request.del(`lesson/homework/${item.id}`);
        if (res.status == 204) {
          state.uploadedList.splice(state.uploadedList.indexOf(item), 1);
        }
      });
    };
    const confirmWork = () => {
      Dialog.confirm({
        title: state.lang.confirm_work,
        confirmButtonText: state.lang.yes,
        cancelButtonText: state.lang.no,
      }).then(async () => {
        // var res = await request.post("lesson/homework/", state.uploadeditem);
        // if (res.status == 201) {
        //   state.uploadeditem.id = res.data.id;
        //   state.uploadedList.push(state.uploadeditem);
        // }
      });
    };
    getList();
    return {
      ...toRefs(state),
      common,
      getList,
      oversize,
      hasFile,
      beforeRead,
      afterRead,
      deleteUploadedFile,
      changeField,
      confirmWork,
      uploadFile,
    };
  },
};
</script>
    
<style lang="less" scoped>
.commentupdate {
  flex: 1;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /deep/.van-cell-group {
    padding: 0;
    margin: 0;
  }
  /deep/.van-field__control {
    height: 300px !important;
  }
  /deep/.van-field {
    padding: 24px;
  }
  /deep/.van-cell {
    font-size: 32px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #000000;
    line-height: 44px;
  }
  /deep/.van-uploader__upload {
    width: 180px;
    height: 180px;
    background: #eaeaea;
  }
  /deep/.van-uploader__file {
    width: 180px;
    height: 180px;
    background: #eaeaea;
  }
  /deep/.van-uploader__preview {
    width: 180px;
    height: 180px;
    background: #eaeaea;
  }
  /deep/.van-icon-plus {
    font-size: 80px;
    color: #666666;
  }
  /deep/.van-uploader__preview-image {
    width: 180px;
    height: 180px;
  }
  /deep/.van-uploader__preview-delete {
    width: 50px;
    height: 50px;
  }
  /deep/.van-uploader__preview-delete-icon {
    font-size: 48px;
  }
  .uploaderlist {
    margin-top: 40px;
    text-align: left;
  }
  .uploadfile {
    flex: 1;
    // margin-top: 30px;
    padding: 30px 20px;
    overflow-y: auto;
    /deep/.van-uploader,
    .van-uploader__wrapper {
      width: 100%;
    }
    /deep/.van-uploader__input-wrapper {
      width: 100%;
      .van-button {
        width: 100%;
        height: 88px;
        line-height: 88px;
        border-radius: 20px;
        font-size: 32px;
      }
    }
    .uploadfile_item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 30px;
      // width: 100%;
      height: 88px;
      line-height: 88px;
      border-radius: 20px;
      font-size: 32px;
      border: 1px solid #a5907e;
      margin-bottom: 30px;
      color: #a5907e;
      padding: 10px 20px;
      box-sizing: border-box;
      .title {
        width: 90%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  .footer_btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 6px 20px 20px;
    background-color: #fff;
    box-shadow: 0px -2px 7px 0px rgba(0, 0, 0, 0.15);
    /deep/.van-button {
      width: 100%;
      height: 88px;
      border-radius: 12px;
      font-size: 32px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      line-height: 44px;
      padding: 20px 40px;
      background-color: #a5907e;
      color: #fff;
      border-color: #a5907e;
    }
  }
}
</style>